
import type { PropType } from "vue";
import Vue from "vue";
import type { Location } from "~/types/branch";
import { MarkerIcon } from "~/components/UI/icons";

export default Vue.extend({
  components: {
    MarkerIcon,
  },
  props: {
    item: {
      type: Object as PropType<Location>,
      default: () => ({}) as Location,
    },
  },
  computed: {
    boldText(): string {
      if (this.item.matched_substrings) {
        const normalTextPart = this.item.description.split(",")[0];
        const matchedDescriptionSubstrings =
          this.item?.matched_substrings.description.filter(
            (substring) => substring.offset < normalTextPart.length
          );

        const startIndex = matchedDescriptionSubstrings[0]?.offset;
        const lastElement =
          matchedDescriptionSubstrings[matchedDescriptionSubstrings.length - 1];
        const lastIndex =
          (lastElement?.offset ?? 0) + (lastElement?.length ?? 0);

        const boldText =
          startIndex !== undefined
            ? this.item.description.substring(startIndex, lastIndex)
            : "";

        return this.item.description.startsWith(boldText) ? boldText : "";
      }
      return "";
    },
    normalText(): string {
      return `${this.splitText[0].substring(this.boldText.length)}${
        this.splitText.length > 1 ? "," : ""
      }`;
    },
    thinText(): string {
      const clonedSplitText = [...this.splitText];
      return clonedSplitText.splice(1).join(",");
    },
    splitText(): string[] {
      return this.item.description.split(",");
    },
  },
});
