
import Vue from "vue";
import type { PropType } from "vue";
import type { Branch } from "~/types/branch";
import { maskQuantity } from "~/lib/stock";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";

export default Vue.extend({
  name: "SetBranchModal",
  components: { DefaultButton },
  props: {
    branch: {
      type: Object as PropType<Branch>,
      default: () => ({}) as Branch,
    },
    showStockMessaging: Boolean as PropType<boolean>,
    stockQty: {
      type: String as PropType<string>,
      default: null,
    },
  },
  computed: {
    branchName(): string {
      return `/branches/${this.branch.name
        ?.toLowerCase()
        .split(" ")
        .join("-")}`;
    },

    storeAddress(): string {
      const address = [...this.branch.address];
      address.splice(0, 1); // delete the branch name
      address.splice(3, 1); // delete the county
      address.splice(4, 1); // delete the country
      return address.join(", ");
    },
  },

  methods: {
    maskQuantity,
  },
});
