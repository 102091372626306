
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import VAutocomplete from "v-autocomplete";
import { pluck } from "ramda";
import BranchListItem from "./BranchListItem.vue";
import AutocompleteItemTemplate from "./AutocompleteItemTemplate.vue";
import BaseModal from "~/components/UI/modals/BaseModal.vue";
import FormLabel from "~/components/UI/forms/FormLabel.vue";
import { Branch, Location } from "~/types/branch";
import { getDetails } from "~/services/woosmap.service";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";

export default Vue.extend({
  name: "CustomerPreferredBranchModal",
  components: {
    BaseModal,
    DefaultButton,
    FormLabel,
    BranchListItem,
    VAutocomplete,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      item: null as Location | null,
      searchText: "",
      template: AutocompleteItemTemplate,
      domainName: "",
      locationWarning: false,
      autocompleteInvalid: false,
      latestSearchByLocationNotFound: null,
      searchActive: false,
    };
  },
  computed: {
    ...mapGetters("branch", [
      "nearestBranches",
      "postcodes",
      "storeNotFoundByLocationPostcode",
      "showStockFor",
    ]),
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("stock", ["stockForCollection"]),
    showError(): boolean {
      return (
        this.autocompleteInvalid ||
        this.storeNotFoundByLocationPostcode !== null
      );
    },
  },
  watch: {
    async "$store.state.branch.nearestBranches"(branches: Branch[]) {
      if (this.showStockFor === null) {
        return;
      }

      await this.$store.dispatch("stock/getStockForSites", {
        axios: this.$axios,
        products: [this.showStockFor],
        sites: pluck("id", branches),
      });
    },
  },
  mounted() {
    this.domainName = window.location.origin;
  },
  methods: {
    ...mapActions("branch", [
      "getNearestBranches",
      "updateSelectedBranch",
      "getPostcodeAutocompleteData",
    ]),
    ...mapMutations("branch", [
      "setPostcodes",
      "setNearestBranches",
      "resetStoreNotFoundByLocationPostcode",
    ]),
    ...mapActions("branch", ["setStore"]),

    getLabel(item: Location): string {
      return item.description;
    },
    updateItems(query: string) {
      this.getPostcodeAutocompleteData({
        query,
        country: [this.$config.localeInstance],
      });
    },
    updateBranch({ name, id }: Branch) {
      this.setStore({ name, id });
      this.$emit("close");
    },

    getUserLocation() {
      this.searchActive = true;

      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.getNearestBranches({
            query: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            locale: this.$nuxt.context.i18n.locale,
          }).finally(() => {
            this.searchActive = false;
          });
          this.locationWarning = false;
        },
        () => {
          this.locationWarning = true;
          this.searchActive = false;
        }
      );
    },

    getBranchesByPostcode() {
      if (this.searchText.length > 2) {
        this.searchActive = true;

        this.getNearestBranches({
          query: { postcode: this.searchText },
          locale: this.$nuxt.context.i18n.locale,
        }).finally(() => {
          this.searchActive = false;
        });

        this.autocompleteInvalid = false;
      } else {
        this.autocompleteInvalid = true;
      }
    },

    async getBranches(item: Location) {
      const locality = await getDetails(
        this.$store?.state?.branch?.postcodeApiKey,
        item.public_id
      );

      this.getNearestBranches({
        query: {
          latitude: locality.geometry.location.lat,
          longitude: locality.geometry.location.lng,
        },
        locale: this.$nuxt.context.i18n.locale,
      });
      this.searchText = item.description;
      this.item = item;
    },

    changeSearchText(text: string) {
      if (!text) {
        this.setPostcodes([]);
      }
      this.searchText = text;
      this.locationWarning = false;
    },

    closeModal() {
      this.searchText = "";
      this.locationWarning = false;
      this.autocompleteInvalid = false;
      this.resetStoreNotFoundByLocationPostcode();
      this.setNearestBranches([]);
      this.setPostcodes([]);
      this.$emit("close");
    },
  },
});
